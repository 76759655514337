:root {
	--color-primary: #0e0b36;
	--color-primary-on-surface: #06041f;
	--color-secondary: #736aed;
	--color-secondary-on-surface: #3b395c;

	--color-surface-1: #f5f4fb;
	--color-surface-2: #ecebf5;
	--color-surface-3: #dddcec;
	--color-surface-4: #bfb9f3;

	--color-overlay: #0e0b3666;
	--color-placeholder: #8e8bb5;
	--color-disabled: #cac9dd;
	--color-positive: #bcfe2f;
	--color-negative: #ff0074;
	--color-warning: #fef62f;

	--color-logo: #bcfe2f;
}

@tailwind base;

@import 'components/arrow';
@import 'components/avatar';
@import 'components/autocomplete';
@import 'components/breadcrumbs';
@import 'components/button';
@import 'components/chart';
@import 'components/checkbox';
@import 'components/datepicker';
@import 'components/dropzone';
@import 'components/editor';
@import 'components/input';
@import 'components/field';
@import 'components/map';
@import 'components/message';
@import 'components/menu';
@import 'components/modal';
@import 'components/notifications';
@import 'components/overlay';
@import 'components/pagination';
@import 'components/radio';
@import 'components/select';
@import 'components/sidebar';
@import 'components/spinner';
@import 'components/table';
@import 'components/tabs';
@import 'components/tag';
@import 'components/text-editor';
@import 'components/textarea';
@import 'components/tooltip';

@layer base {
	* {
		@apply tw-outline-offset-0 placeholder:tw-select-none focus:tw-outline-0;
	}

	::selection {
		@apply tw-bg-secondary tw-text-white;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: none;
		-webkit-background-clip: text;
	}

	body {
		@apply tw-bg-surface-2 tw-text-primary-on-surface;
	}

	h1,
	.ui-h1 {
		@apply tw-text-[38px] tw-font-medium tw-leading-[28px];
	}
	h2,
	.ui-h2 {
		@apply tw-text-[28px] tw-font-bold tw-leading-[32px];
	}
	h3,
	.ui-h3 {
		@apply tw-text-[24px] tw-font-bold tw-leading-[28px];
	}
	h4,
	.ui-h4 {
		@apply tw-text-[20px] tw-font-bold tw-leading-[24px];
	}
	h5,
	.ui-h5 {
		@apply tw-text-[16px] tw-font-bold tw-leading-[20px];
	}
}

/* Avatar */

.ui-avatar-primary {
	@apply tw-rounded-full tw-bg-white tw-text-secondary;
}

/* Spinner */

.ui-spinner-primary {
	@apply before:tw-border-2 before:tw-border-surface-3 before:tw-opacity-[0.3] after:tw-border-2 after:tw-border-t-secondary;
}

/* Button */

.ui-button-primary {
	@apply tw-gap-x-[8px] tw-rounded-[20px] tw-bg-primary tw-px-[20px] tw-py-[10px] tw-text-center tw-text-[16px] tw-font-bold tw-text-white disabled:!tw-bg-disabled;
}
.ui-button-primary.ui-button-loading .ui-spinner {
	@extend .ui-spinner-primary;
	@apply tw-h-[20px] tw-w-[20px];
}

.ui-button-secondary {
	@apply tw-gap-x-[8px] tw-rounded-[20px] tw-border tw-border-primary tw-px-[18px] tw-py-[9px] tw-text-center tw-text-[16px] tw-font-bold disabled:!tw-border-disabled disabled:!tw-text-disabled;
}
.ui-button-secondary.ui-button-negative {
	@apply tw-border-negative tw-text-negative;
}
.ui-button-secondary.ui-button-loading .ui-spinner {
	@extend .ui-spinner-primary;
	@apply tw-h-[20px] tw-w-[20px];
}

.ui-button-tertiary {
	@apply tw-min-h-[32px] tw-min-w-[32px] tw-gap-x-[8px] tw-rounded-[4px] tw-bg-transparent tw-px-[8px] tw-py-[4px] tw-text-center tw-text-secondary hover:tw-bg-surface-3 disabled:!tw-bg-transparent disabled:!tw-text-disabled;
}
.ui-button-tertiary.ui-button-icon {
	@apply tw-p-[4px];
}
.ui-button-tertiary.ui-button-loading .ui-spinner {
	@extend .ui-spinner-primary;
	@apply tw-h-[20px] tw-w-[20px];
}

/* Chart */

.ui-chart-primary .ct-label {
	@apply tw-text-[14px];
	fill: var(--color-placeholder);
	color: var(--color-placeholder);
}
.ui-chart-primary .ct-label-point {
	@apply tw-h-[20px] tw-min-w-[30px] tw-bg-secondary tw-text-white;
}
.ui-chart-primary .ct-grid {
	stroke: var(--color-disabled);
	stroke-width: 1px;
	stroke-dasharray: 2px;
}
.ui-chart-primary .ct-point {
	stroke-width: 10px;
}
.ui-chart-primary .ct-line {
	stroke-width: 4px;
}
.ui-chart-primary .ct-area {
	fill-opacity: 0.1;
}
.ui-chart-primary .ct-bar {
	stroke-width: 10px;
}
.ui-chart-primary .ct-slice-donut {
	stroke-width: 60px;
}

.ui-chart-primary .ct-series:nth-child(1) .ct-bar,
.ui-chart-primary .ct-series:nth-child(1) .ct-line,
.ui-chart-primary .ct-series:nth-child(1) .ct-point,
.ui-chart-primary .ct-series:nth-child(1) .ct-slice-donut {
	stroke: var(--color-secondary);
}

.ui-chart-primary .ct-series:nth-child(1) .ct-area,
.ui-chart-primary .ct-series:nth-child(1) .ct-slice-pie {
	fill: var(--color-secondary);
}

/* Checkbox */

.ui-checkbox-primary .ui-checkbox-checkmark {
	@apply tw-relative tw-top-[2px] tw-h-[20px] tw-w-[20px] tw-border tw-border-disabled tw-bg-white before:tw-absolute before:tw-inset-[2px];
}
.ui-checkbox-primary .ui-checkbox-label {
	@apply tw-pl-[10px];
}
.ui-checkbox-primary input[type='checkbox']:checked + .ui-checkbox-checkmark {
	@apply before:tw-bg-secondary;
}
.ui-checkbox-primary.ui-checkbox-positive input[type='checkbox']:checked + .ui-checkbox-checkmark {
	@apply before:tw-bg-positive;
}
.ui-checkbox-primary.ui-checkbox-negative input[type='checkbox']:checked + .ui-checkbox-checkmark {
	@apply before:tw-bg-negative;
}
.ui-checkbox-primary input[type='checkbox']:checked:disabled + .ui-checkbox-checkmark {
	@apply before:tw-bg-disabled;
}
.ui-field.ui-field-error .ui-checkbox-primary .ui-checkbox-checkmark {
	@apply tw-border-negative;
}

.ui-checkbox-toggle-primary {
	@apply tw-gap-x-[10px];
}
.ui-checkbox-toggle-primary .ui-checkbox-checkmark {
	@apply tw-relative tw-h-[24px] tw-w-[48px] tw-rounded-[24px] tw-bg-placeholder before:tw-absolute before:tw-inset-y-[2px] before:tw-left-[2px] before:tw-h-[20px] before:tw-w-[20px] before:tw-rounded-full before:tw-bg-white before:tw-transition-all before:tw-duration-200;
}
.ui-checkbox-toggle-primary input[type='checkbox']:checked + .ui-checkbox-checkmark {
	@apply tw-bg-positive before:tw-left-[calc(100%-2px)] before:tw--translate-x-full;
}

/* Dropzone */

.ui-dropzone-primary {
	@apply tw-min-h-[160px] tw-max-w-content tw-rounded-[8px] tw-border-[2px] tw-border-dashed tw-border-disabled tw-bg-surface-1 tw-p-[10px] focus:tw-border-secondary;
}
.ui-dropzone-primary.ui-dropzone-disabled {
	@apply focus:tw-border-disabled;
}
.ui-field.ui-field-error .ui-dropzone-primary {
	@apply tw-border-negative focus:!tw-border-negative;
}
.ui-dropzone-primary .ui-dropzone-placeholder {
	@apply tw-text-center tw-text-[16px] tw-text-placeholder;
}
.ui-dropzone-primary .ui-dropzone-files {
	@apply tw-grid-cols-4 tw-gap-[10px];
}
.ui-dropzone-primary .ui-dropzone-file {
	@apply tw-rounded-[4px];
}
.ui-dropzone-file.ui-dropzone-file-multiple {
	@apply tw-max-h-[136px] tw-max-w-[186.5px];
}
.ui-dropzone-primary .ui-dropzone-file-name {
	@apply tw-text-[14px];
}
.ui-dropzone-primary .ui-dropzone-file-cover {
	@apply tw-col-span-4;
}
.ui-dropzone-primary .ui-dropzone-file-icon {
	@apply tw-h-[48px] tw-w-[48px];
}
.ui-dropzone-primary .ui-dropzone-file-remove-button {
	@extend .ui-button-tertiary;
	@apply tw-right-[10px] tw-top-[10px] tw-bg-white;
}
.ui-dropzone-primary .ui-dropzone-file-remove-button svg {
	@apply tw-h-[20px] tw-w-[20px];
}

/* Input */

.ui-input-primary {
	@apply tw-min-h-[48px] tw-gap-x-[8px] tw-rounded-[4px] tw-border tw-border-disabled tw-bg-white tw-p-[10px] tw-transition-colors tw-duration-200;
}

label.ui-input-primary:not(.ui-input-disabled):not(.ui-input-read-only) {
	@apply focus-within:tw-border-secondary;
}
input.ui-input-primary {
	@apply focus:tw-border-secondary;
}
.ui-input-primary input {
	@apply read-only:tw-text-placeholder disabled:tw-text-placeholder;
}
.ui-input-primary .ui-input-icon {
	@apply tw-h-[20px] tw-w-[20px] tw-text-disabled;
}
.ui-field.ui-field-error .ui-input-primary {
	@apply tw-border-negative focus-within:!tw-border-negative;
}
.ui-input-primary {
	@apply tw-max-w-content;
}
.ui-input-primary ~ .ui-field-helpers .ui-field-helper-error {
	@apply tw-text-negative;
}
.ui-input-primary .ui-input-button-remove {
	@apply tw-rounded-full tw-bg-secondary tw-p-[2px] tw-text-white;
}
.ui-input-primary .ui-input-button-remove svg {
	@apply tw-h-[10px] tw-w-[10px];
}
.ui-input-primary.ui-input-number .ui-input-number-button svg {
	@apply tw-h-[16px] tw-w-[16px];
}
.ui-input-primary.ui-input-number .ui-input-number-button:disabled svg {
	@apply tw-text-disabled;
}
.ui-input-primary.ui-input-number .ui-input-number-button-decrease {
	@apply tw-order-1;
}
.ui-input-primary.ui-input-number input {
	@apply tw-order-2 tw-text-center;
}
.ui-input-primary.ui-input-number .ui-input-number-button-increase {
	@apply tw-order-3;
}
.ui-input-primary.ui-input-password .ui-input-password-toggle svg {
	@apply tw-h-[20px] tw-w-[20px] tw-text-disabled;
}
/*Костыль*/
.ui-input-primary.ui-input-search {
	@apply tw-flex-row-reverse;
}
.ui-input-primary .ui-input-search-button svg {
	@apply tw-h-[16px] tw-w-[16px];
}
.ui-input-primary.ui-input-time {
	@apply tw-w-[100px];
}

/* Tags */

.ui-tag-lightgrey {
	@apply tw-border-disabled tw-bg-disabled tw-text-primary-on-surface;
}
.ui-tag-lightgrey-inverted {
	@apply tw-border-disabled tw-text-disabled;
}
.ui-tag-grey {
	@apply tw-border-placeholder tw-bg-placeholder tw-text-white;
}
.ui-tag-green {
	@apply tw-border-positive tw-bg-positive tw-text-primary-on-surface;
}
.ui-tag-red {
	@apply tw-border-negative tw-bg-negative tw-text-white;
}
.ui-tag-yellow {
	@apply tw-border-warning tw-bg-warning tw-text-primary-on-surface;
}
.ui-tag-purple {
	@apply tw-border-secondary tw-bg-secondary tw-text-white;
}
/*TODO: add missing*/
.ui-tag-purple-inverted {
	@apply tw-border-secondary tw-text-secondary;
}

.ui-tags-primary {
	@apply tw-gap-[8px];
}
.ui-tag-primary,
.ui-tags-primary .ui-tag {
	@apply tw-gap-x-[4px] tw-rounded-[12px] tw-border tw-px-[12px] tw-py-[1px] tw-text-[14px];
}
.ui-tags-primary .ui-tag-more {
	@extend .ui-tag-lightgrey;
}
.ui-tag-primary svg,
.ui-tags-primary .ui-tag svg {
	@apply tw-h-[10px] tw-w-[10px];
}

.ui-tags-secondary {
	@apply tw-gap-[8px];
}
.ui-tag-secondary,
.ui-tags-secondary .ui-tag {
	@apply tw-rounded-[12px] tw-border tw-px-[12px] tw-py-[2px] tw-text-[14px];
}
.ui-tags-secondary .ui-tag-more {
	@extend .ui-tag-lightgrey-inverted;
}
.ui-tag-secondary svg,
.ui-tags-secondary .ui-tag svg {
	@apply tw-h-[12px] tw-w-[12px];
}
.ui-field.ui-field-error .ui-tag {
	@apply tw-border-negative;
}

/* Datepicker */

.ui-datepicker-primary {
	@apply tw-gap-[8px];
}
.ui-datepicker-primary .ui-input-date {
	@apply tw-w-[180px];
	@extend .ui-input-primary;
}
.ui-datepicker-primary .ui-input-date-opened {
	@apply tw-border-secondary;
}
.ui-datepicker-primary .ui-input-time {
	@apply focus-within:tw-border-secondary;
	@extend .ui-input-primary;
}
.ui-datepicker-primary .ui-datepicker-panel {
	@apply tw-w-[360px] tw-max-w-full tw-rounded-[4px] tw-border tw-border-disabled tw-bg-white;
}
.ui-datepicker-primary .ui-datepicker-panel-nav {
	@apply tw-gap-[2px] tw-p-[10px];
}
.ui-datepicker-primary .ui-datepicker-panel-nav-button {
	@apply tw-min-h-[32px] tw-min-w-[32px] tw-rounded-[4px] hover:tw-bg-surface-2;
}
.ui-datepicker-primary .ui-datepicker-panel-nav-button svg {
	@apply tw-h-[16px] tw-w-[16px];
}
.ui-datepicker-primary .ui-datepicker-calendar {
	@apply tw-p-[10px];
}
.ui-datepicker-primary .ui-datepicker-calendar-header {
	@apply tw-p-[5px] tw-text-[14px] tw-font-bold tw-lowercase;
}
.ui-datepicker-primary .ui-datepicker-calendar-button {
	@apply tw-min-h-[36px] tw-min-w-[36px] tw-rounded-[4px] tw-border-2 tw-border-transparent hover:tw-bg-surface-2 disabled:tw-bg-transparent disabled:tw-text-disabled;
}
.ui-datepicker-primary .ui-datapicker-calendar-button-today {
	@apply tw-border-surface-2;
}
.ui-datepicker-primary .ui-datepicker-calendar-button-active {
	@apply tw-border-secondary tw-bg-secondary tw-text-white hover:tw-bg-secondary;
}
.ui-field.ui-field-error .ui-datepicker-primary .ui-input {
	@apply tw-border-negative focus-within:!tw-border-negative;
}

/* Editor */

.ui-editor-primary .codex-editor {
	@apply /*focus-within:tw-border-secondary*/ tw-min-h-[8em] tw-px-[20px];
	@extend .ui-input-primary;
}
.ui-field-error .ui-editor-primary .codex-editor {
	@apply tw-border-negative;
}
.ui-editor-primary .ce-toolbar__plus {
	@apply tw-mt-[12px] tw-h-[20px] tw-w-[20px] tw-translate-x-[-50%] tw-rounded-full tw-bg-positive tw-text-white hover:tw-bg-positive;
}
.ui-editor-primary .ce-toolbar__plus svg {
	@apply !tw-h-[20px] !tw-w-[20px];
}
.ui-editor-primary .ce-inline-tool {
	@apply !tw-px-[3px] !tw-py-[6px];
}
.ui-editor-primary .ce-toolbar__settings-btn {
	@apply tw-mt-[12px] tw-h-[24px] tw-w-[24px] tw-translate-x-[50%] tw-rounded-[4px] tw-border tw-border-surface-3 tw-bg-white hover:tw-bg-surface-3;
}
.ui-editor-primary .ce-toolbar__settings-btn svg {
	@apply !tw-h-[24px] !tw-w-[24px];
}
.ui-editor-primary .ce-toolbox {
	@apply tw-top-[28px] tw-ml-[-10px];
}
.ui-editor-primary .ce-settings {
	@apply tw-top-[32px] tw-mr-[-11px];
}

.ui-editor-primary .ce-delimiter {
	@apply tw-text-[30px] before:tw-inline-block before:tw-content-['***'];
}

/* Map */

.ui-map-primary .ui-spinner {
	@extend .ui-spinner-primary;
}
.ui-map .ui-input-search {
	@extend .ui-input-primary;
}

/* Modal */

.ui-modal-primary {
	@apply tw-rounded-[4px] tw-bg-white tw-p-[20px];
}
.ui-modal-primary .ui-modal-button-close {
	@apply tw-right-[20px] tw-top-[20px];
}
.ui-modal-primary .ui-modal-button-close > svg {
	@apply tw-h-[16px] tw-w-[16px] tw-text-placeholder;
}

/* Overlay */

.ui-overlay-primary .ui-overlay-content {
	@apply tw-p-[20px];
}
.ui-overlay-primary .ui-overlay-backdrop {
	@apply tw-bg-overlay;
}
.ui-overlay-primary .ui-modal {
	@extend .ui-modal-primary;
}

/*
.ui-tabs-primary {
	@apply tw-flex tw-items-center tw-gap-x-[40px];
}
.ui-tabs-primary .ui-tab {
	@apply tw-gap-x-[8px] tw-border-[3px] tw-border-transparent tw-pb-[5px] tw-font-medium tw-leading-[28px] tw-text-secondary-on-surface;
}
.ui-tabs-primary .ui-tab.active {
	@apply tw-border-b-primary tw-text-primary-on-surface;
}
*/

/* Radio */

.ui-radio-primary .ui-radio-checkmark {
	@apply tw-relative tw-top-[2px] tw-h-[20px] tw-w-[20px] tw-rounded-full tw-border tw-border-disabled tw-bg-white before:tw-absolute before:tw-inset-[2px] before:tw-rounded-full;
}
.ui-radio-primary .ui-radio-label {
	@apply tw-pl-[10px];
}
.ui-radio-primary input[type='radio']:checked + .ui-radio-checkmark {
	@apply before:tw-bg-secondary;
}
.ui-radio-primary.ui-radio-positive input[type='radio']:checked + .ui-radio-checkmark {
	@apply before:tw-bg-positive;
}
.ui-radio-primary.ui-radio-negative input[type='radio']:checked + .ui-radio-checkmark {
	@apply before:tw-bg-negative;
}
.ui-radio-primary input[type='radio']:checked:disabled + .ui-radio-checkmark {
	@apply before:tw-bg-disabled;
}
.ui-field.ui-field-error .ui-radio-primary .ui-radio-checkmark {
	@apply tw-border-negative;
}

/* Tooltip */

.ui-tooltip-primary {
	@apply tw-max-w-[300px] tw-rounded-[16px] tw-bg-surface-3 tw-p-[16px];
}

.ui-arrow-primary {
	@apply before:tw-border-surface-3;
}

/* Label */

.ui-label-primary {
	@apply tw-text-[14px] tw-leading-[17px] tw-text-disabled;
}

/* Field */

.ui-field-primary {
	@apply tw-max-w-content tw-gap-y-[6px];
}
.ui-field-primary .ui-label {
	@extend .ui-label-primary;
}
.ui-field-primary .ui-field-helpers {
	@apply tw-gap-x-[32px] tw-text-[14px] tw-text-disabled;
}
.ui-field-primary .ui-field-errors {
	@apply tw-gap-y-[4px];
}
.ui-field-primary .ui-field-error {
	@apply tw-text-[14px] tw-text-negative;
}

/* Link */

.ui-link-primary {
	@apply tw-text-primary-on-surface;
}
.ui-link-secondary {
	@apply tw-text-secondary;
}

/* Message */

.ui-message-primary > .ui-message-body {
	@apply tw-items-start;
}

/* Notifications */

.ui-notification-primary {
	@apply tw-gap-x-[10px] tw-rounded-[16px] tw-border tw-p-[16px];
}
/*TODO: change*/
.ui-notification-primary.ui-notification-success {
	@apply tw-border-positive tw-bg-positive;
}
.ui-notification-primary.ui-notification-info {
	@apply tw-border-warning tw-bg-warning;
}
.ui-notification-primary.ui-notification-error {
	@apply tw-border-negative tw-bg-negative tw-text-white;
}

/* Pagination */

.ui-pagination-primary {
	@apply tw-divide-x tw-divide-surface-2;
}
.ui-pagination-primary .ui-pagination-button {
	@apply tw-min-h-[48px] tw-min-w-[48px] tw-bg-white tw-transition-colors tw-duration-200 first:tw-rounded-l-[4px] last:tw-rounded-r-[4px] hover:tw-bg-secondary hover:tw-text-white active:tw-bg-secondary active:tw-text-white disabled:tw-bg-white disabled:tw-text-disabled;
}
.ui-pagination-primary .ui-pagination-button svg {
	@apply tw-h-[16px] tw-w-[16px];
}
.ui-pagination-primary .ui-pagination-info {
	@apply tw-bg-white tw-px-[20px];
}

/* Scrollbar */

.ui-scrollbar-primary::-webkit-scrollbar {
	@apply tw-w-[10px];
}
.ui-scrollbar-primary::-webkit-scrollbar-track {
	@apply tw-bg-transparent;
}
.ui-scrollbar-primary::-webkit-scrollbar-thumb {
	@apply tw-rounded-[12px] tw-bg-secondary-on-surface;
}

/* Select */

.ui-select-primary .ui-select-button {
	@extend .ui-input-primary;
}
.ui-select-primary .ui-select-button-opened {
	@apply tw-border-secondary;
}
.ui-field.ui-field-error .ui-select-primary .ui-select-button {
	@apply tw-border-negative focus:!tw-border-negative;
}
.ui-select-primary .ui-select-button-arrow {
	@apply tw-h-[16px] tw-w-[16px];
}
.ui-select-primary .ui-select-options-more {
	@apply tw-rounded-[12px] tw-bg-secondary tw-px-[10px] tw-py-[2px] tw-text-[14px] tw-text-white;
}
.ui-select-primary .ui-select-button-opened .ui-select-button-arrow {
	@apply tw-rotate-180;
}
.ui-select-primary .ui-select-button-icon {
	@apply tw-h-[16px] tw-w-[16px];
}
/*TODO: add arrow*/
.ui-select-primary .ui-select-button-placeholder {
	@apply tw-text-placeholder;
}
.ui-select-primary .ui-select-button-options {
	@apply tw-gap-[8px] tw-text-left;
}
.ui-select-primary .ui-select-button-option {
	@extend .ui-tag-primary;
	@extend .ui-tag-purple;
}
.ui-select-primary .ui-select-button-option-remove {
	@apply !tw-h-[12px] !tw-w-[12px];
}
.ui-select-primary .ui-select-options {
	@apply tw-divide-y tw-divide-surface-2 tw-overflow-hidden tw-rounded-[4px] tw-border tw-border-disabled tw-bg-white;
}
.ui-select-primary .ui-select-options-search {
	@apply tw-gap-x-[8px] tw-p-[10px];
}
.ui-select-primary .ui-select-options-search .ui-input-icon {
	@apply tw-h-[16px] tw-w-[16px] tw-text-disabled;
}
.ui-select-primary .ui-select-options-search-placeholder {
	@apply tw-p-[10px] tw-text-surface-3;
}
.ui-select-options-search-empty {
	@apply tw-p-[10px] tw-text-surface-3;
}
.ui-select-primary .ui-select-option {
	@apply tw-gap-x-[12px] tw-p-[10px] hover:tw-bg-surface-2;
}
.ui-select-primary .ui-select-option.ui-select-option-active {
	@apply tw-bg-secondary tw-text-white;
}
.ui-select-primary .ui-select-option svg {
	@apply tw-my-[3px] tw-h-[18px] tw-w-[18px];
}

/* Autocomplete */

.ui-autocomplete-primary {
	@apply tw-gap-y-[8px];
}
.ui-autocomplete-primary {
	@apply tw-max-w-content;
}
.ui-autocomplete-primary .ui-autocomplete-button-input {
	@extend .ui-input-primary;
}
.ui-autocomplete-primary .ui-autocomplete-button-opened {
	@apply tw-border-secondary;
}
.ui-autocomplete-primary .ui-autocomplete-options-more {
	@apply tw-rounded-[12px] tw-bg-secondary tw-px-[10px] tw-py-[2px] tw-text-[14px] tw-text-white;
}
.ui-autocomplete-primary:not(.ui-autocomplete-disabled):not(.ui-autocomplete-read-only) .ui-autocomplete-button {
	@apply focus-within:tw-border-secondary;
}
.ui-field.ui-field-error .ui-autocomplete-primary .ui-autocomplete-button-input {
	@apply tw-border-negative focus:!tw-border-negative;
}
.ui-autocomplete-primary .ui-autocomplete-button-icon {
	@apply tw-h-[16px] tw-w-[16px] tw-text-disabled;
}
.ui-autocomplete-primary .ui-autocomplete-selected {
	@apply tw-gap-[8px] tw-text-left;
}
.ui-autocomplete-primary .ui-autocomplete-selected-item {
	@extend .ui-tag-primary;
	@extend .ui-tag-purple;
}
.ui-autocomplete-primary .ui-autocomplete-selected-item-remove {
	@apply !tw-h-[12px] !tw-w-[12px];
}
.ui-autocomplete-primary .ui-autocomplete-options {
	@apply tw-overflow-hidden tw-rounded-[4px] tw-border tw-border-disabled tw-bg-white;
}
.ui-autocomplete-primary .ui-autocomplete-options-search-empty {
	@apply tw-p-[10px] tw-text-surface-3;
}
.ui-autocomplete-primary .ui-autocomplete-option {
	@apply tw-gap-x-[12px] tw-p-[10px] hover:tw-bg-surface-2;
}
.ui-autocomplete-primary .ui-autocomplete-option.ui-autocomplete-option-active {
	@apply tw-bg-secondary tw-text-white;
}
.ui-autocomplete-primary .ui-autocomplete-option svg {
	@apply tw-my-[3px] tw-h-[18px] tw-w-[18px];
}
.ui-autocomplete-primary .ui-autocomplete-option-show-all-button {
	@apply tw-gap-x-[10px] tw-p-[10px] tw-text-left hover:tw-bg-surface-2;
}

/* Menu */

.ui-menu-primary {
	@apply tw-divide-surface-2 tw-overflow-hidden tw-rounded-[4px] tw-border tw-border-disabled tw-bg-white;
}
.ui-menu-primary .ui-menu-item {
	@apply tw-gap-x-[12px] tw-p-[10px] hover:tw-bg-surface-2;
}
.ui-menu-primary .ui-menu-item.ui-menu-item-active {
	@apply tw-bg-secondary tw-text-white;
}
.ui-menu-primary .ui-menu-item svg {
	@apply tw-my-[3px] tw-h-[18px] tw-w-[18px];
}

/* Sidebar */

.ui-sidebar-primary {
	@apply tw-bg-primary;
}
.ui-sidebar-primary .ui-sidebar-header {
	@apply tw-gap-y-[36px] tw-px-[36px] tw-py-[12px];
}
.ui-sidebar-primary .ui-sidebar-button-close svg {
	@apply tw-h-[24px] tw-w-[24px] tw-text-white;
}
/*
.ui-sidebar-primary {
	@apply tw-divide-y tw-divide-disabled tw-bg-white;
}
.ui-sidebar-primary.ui-sidebar-left {
	@apply tw-border-r tw-border-disabled;
}
.ui-sidebar-primary.ui-sidebar-right {
	@apply tw-border-l tw-border-disabled;
}
.ui-sidebar-primary.ui-sidebar-top {
	@apply tw-border-b tw-border-disabled;
}
.ui-sidebar-primary.ui-sidebar-bottom {
	@apply tw-border-t tw-border-disabled;
}
.ui-sidebar-primary .ui-sidebar-header {
	@apply tw-gap-x-[16px] tw-p-[12px];
}
.ui-sidebar-primary .ui-sidebar-button-close {
	@apply tw-h-[20px] tw-w-[20px];
}
*/

/* Table */

.ui-table-primary {
	@apply tw-border-collapse;
}
.ui-table-primary th {
	@apply tw-select-none tw-text-left tw-text-[16px];
}
.ui-table-primary th,
.ui-table-primary td {
	@apply tw-px-[16px] tw-py-[20px];
}
.ui-table-primary tr {
	@apply tw-bg-white;
}
.ui-table-primary tbody tr {
	@apply tw-relative tw-transition-colors tw-duration-200 hover:tw-bg-surface-1;
}
.ui-table-primary tbody tr,
.ui-table-primary tbody th {
	@apply tw-border-t tw-border-surface-2 first:tw-border-t-0;
}
.ui-table-primary th a {
	@apply tw-gap-x-[10px];
}
.ui-table-primary th svg {
	@apply tw-h-[12px] tw-w-[12px];
}

.ui-table-secondary {
	@apply tw-border-collapse;
}
.ui-table-secondary tr {
	@apply tw-bg-white;
}
.ui-table-secondary th,
.ui-table-secondary td {
	@apply tw-px-[16px] tw-py-[20px];
}
.ui-table-secondary tbody tr,
.ui-table-secondary tbody th {
	@apply tw-border-y tw-border-surface-2;
}

/* Breadcrumbs */

.ui-breadcrumbs-primary {
	@apply tw-gap-[5px];
}
.ui-breadcrumbs-primary a {
}
.ui-breadcrumbs-primary .ui-menu {
	@apply tw-divide-y;
	@extend .ui-menu-primary;
}
.ui-breadcrumbs-primary .ui-breadcrumbs-delimeter {
	@apply tw-text-[14px];
}
.ui-breadcrumbs-primary .ui-breadcrumbs-more {
	@apply tw-h-[14px] tw-w-[14px];
}

/* Textarea */

.ui-textarea-primary {
	@apply tw-max-w-content;
}
.ui-textarea-primary:not(textarea) textarea,
textarea.ui-textarea-primary {
	@extend .ui-input-primary;
	@apply tw-min-h-[8em];
}
.ui-textarea-primary,
label.ui-textarea-primary:not(.ui-textarea-disabled):not(.ui-textarea-read-only) textarea {
	@apply focus:tw-border-secondary;
}
.ui-field.ui-field-error .ui-textarea-primary:not(textarea) textarea,
.ui-field.ui-field-error textarea.ui-textarea-primary {
	@apply tw-border-negative focus:!tw-border-negative;
}

@tailwind components;
@tailwind utilities;

.ui-app-layout {
	@apply tw-relative tw-flex tw-w-full;
}
.ui-main-layout {
	@apply tw-relative tw-flex tw-flex-grow tw-flex-col tw-gap-y-[24px];
}
.ui-content-layout {
	@apply tw-relative tw-mx-[36px] tw-mb-[24px] tw-flex tw-flex-shrink-0 tw-flex-grow tw-flex-col tw-gap-[12px];
}
.ui-content-layout-block {
	@apply tw-max-w-full tw-rounded-[10px] tw-bg-white tw-p-[24px] only:tw-flex-grow;
}
.ui-content-filters-layout {
	@apply tw-grid tw-gap-x-[16px] tw-gap-y-[8px];
}
.ui-content-filters-layout .ui-label {
	@apply tw-text-primary-on-surface;
}
.ui-content-filters-layout .ui-input {
	@extend .ui-input-primary;
}
.ui-content-filters-layout .ui-autocomplete {
	@extend .ui-autocomplete-primary;
}
.ui-content-filters-layout .ui-select {
	@extend .ui-select-primary;
}
.ui-content-filters-layout .ui-datepicker {
	@extend .ui-datepicker-primary;
}
.ui-content-filters-layout .ui-datepicker .ui-datepicker-button {
	@apply tw-w-full;
}
.ui-content-filters-layout .ui-datepicker .ui-input-date {
	@apply tw-w-full tw-max-w-full;
}
.ui-content-helpers-layout {
	@apply tw-relative tw-mx-[36px] tw-mb-[24px] tw-flex tw-gap-x-[16px];
}
.ui-content-panel-layout {
	@apply tw-sticky tw-bottom-0 tw-z-[30] tw-flex tw-gap-x-[16px] tw-border-t tw-border-disabled tw-bg-white tw-px-[36px] tw-py-[12px];
}
.ui-form-layout {
	@apply tw-relative tw-flex tw-min-h-full tw-min-w-full tw-justify-center tw-bg-white tw-px-[25px] tw-py-[80px];
}

.ui-app-aside {
	@apply tw-relative tw-hidden tw-w-[260px] tw-flex-shrink-0 tw-flex-grow-0 tw-select-none tw-bg-primary xl:tw-block;
}
.ui-app-aside .ui-app-aside-items {
	@apply tw-flex tw-flex-col-reverse;
}
.ui-app-aside .ui-apps-sidebar-item {
	@apply tw-flex tw-flex-col;
}
.ui-app-aside .ui-app-aside-link {
	@apply tw-flex tw-w-full tw-items-center tw-gap-x-[18px] tw-px-[25px] tw-py-[15px] tw-font-bold tw-text-white tw-transition-colors tw-duration-150 hover:tw-text-logo;
}
.ui-app-aside .ui-app-aside-items .ui-app-aside-items .ui-app-aside-link {
	@apply tw-font-normal;
}
.ui-app-aside .ui-app-aside-item-active .ui-app-aside-link {
	@apply tw-bg-secondary hover:tw-text-white;
}
.ui-app-aside .ui-app-aside-item-active ~ .ui-app-aside-item-active .ui-app-aside-link {
	@apply tw-bg-transparent hover:tw-text-logo;
}

.ui-app-mobile-menu .ui-app-mobile-menu-items {
	@apply tw-flex tw-flex-col-reverse;
}
.ui-app-mobile-menu .ui-apps-mobile-menu-item {
	@apply tw-flex tw-flex-col;
}
.ui-app-mobile-menu .ui-app-mobile-menu-link {
	@apply tw-flex tw-w-full tw-items-center tw-gap-x-[18px] tw-px-[36px] tw-py-[15px] tw-font-bold tw-text-white tw-transition-colors tw-duration-150 hover:tw-text-logo;
}
.ui-app-mobile-menu .ui-app-mobile-menu-items .ui-app-mobile-menu-items .ui-app-mobile-menu-link {
	@apply tw-font-normal;
}
.ui-app-mobile-menu .ui-app-mobile-menu-item-active .ui-app-mobile-menu-link {
	@apply tw-bg-secondary hover:tw-text-white;
}
.ui-app-mobile-menu .ui-app-mobile-menu-item-active ~ .ui-app-mobile-menu-item-active .ui-app-mobile-menu-link {
	@apply tw-bg-transparent hover:tw-text-logo;
}

.ui-search-primary input + button {
	@apply tw-h-[30px] tw-w-[30px] tw-pb-[6px] tw-pl-[6px];
}
.ui-search-primary input + button svg {
	@apply tw-h-[22px] tw-w-[22px];
}
.ui-search-primary-button {
	@apply tw-h-[34px] tw-w-[34px] tw-rounded-[10px] tw-bg-disabled active:tw-bg-primary;
}
.ui-search-primary-button svg {
	@apply tw-h-[22px] tw-w-[22px] tw-text-white;
}

.ui-select-positions .ui-select-button-option .ui-select-button-option-icon,
.ui-select-departments .ui-select-button-option .ui-select-button-option-icon {
	@apply tw-hidden;
}

.ui-screenplay {
	@apply tw-flex tw-flex-col tw-divide-y tw-divide-surface-2 tw-p-0;
	@extend .ui-content-layout-block;
}
.ui-screenplay .ui-screenplay-buttons {
	@apply tw-flex tw-items-center tw-justify-between tw-gap-x-[16px] tw-p-[24px];
}
.ui-screenplay .ui-screenplay-buttons-group {
	@apply tw-flex tw-items-center tw-gap-x-[16px];
}
.ui-screenplay .ui-screenplay-buttons .ui-button {
	@apply tw-text-secondary-on-surface;
	@extend .ui-button-tertiary;
}
.ui-screenplay .ui-screenplay-buttons .ui-button svg {
	@apply tw-h-[20px] tw-w-[20px];
}
.ui-screenplay .ui-screenplay-body {
	@apply tw-relative tw-min-h-[800px] tw-overflow-auto;
}
.ui-screenplay .ui-screenplay-body-blocks {
	@apply tw-relative tw-z-[10] tw-min-h-full tw-min-w-full;
}
.ui-screenplay .ui-screenplay-block {
	@apply tw-absolute tw-z-[10] tw-rounded-[8px] tw-border-[2px] tw-border-surface-2 tw-bg-white tw-transition-colors tw-duration-200 hover:tw-z-[20];
}
.ui-screenplay .ui-screenplay-block-body {
	@apply tw-flex tw-min-h-[60px] tw-items-center tw-justify-between tw-gap-x-[10px] tw-px-[12px] tw-py-[16px];
}
.ui-screenplay .ui-screenplay-block-body-title {
	@apply tw-flex tw-flex-shrink tw-flex-grow tw-items-center tw-gap-x-[8px] tw-text-secondary-on-surface;
}
.ui-screenplay .ui-screenplay-block-body-title svg {
	@apply tw-h-[20px] tw-w-[20px] tw-flex-shrink-0 tw-flex-grow-0;
}
.ui-screenplay .ui-screenplay-block-body-grabber {
	@apply tw-h-[20px] tw-w-[20px] tw-flex-shrink-0 tw-flex-grow-0 tw-cursor-grab tw-text-disabled active:tw-cursor-grabbing;
}
.ui-screenplay .ui-screenplay-block-markers {
	@apply tw-absolute tw-bottom-full tw-flex tw-flex-col tw-gap-x-[4px] tw-pb-[8px];
}
.ui-screenplay .ui-screenplay-block-markers button {
	@extend .ui-button-tertiary;
}
.ui-screenplay .ui-screenplay-block-markers svg {
	@apply tw-h-[20px] tw-w-[20px];
}
.ui-screenplay .ui-screenplay-block-connects {
	@apply tw-absolute tw-top-[50%] tw-flex tw-translate-y-[-50%] tw-flex-col tw-gap-y-[2px];
}
.ui-screenplay .ui-screenplay-block-connects-inputs {
	@apply tw-right-full tw-translate-x-[8px];
}
.ui-screenplay .ui-screenplay-block-connects-outputs {
	@apply tw-left-full tw-translate-x-[-8px];
}
.ui-screenplay-block-connects .ui-checkbox {
	@apply tw-pointer-events-none;
	@extend .ui-checkbox-primary;
}
.ui-screenplay-block-connects .ui-checkbox .ui-checkbox-checkmark {
	@apply tw-pointer-events-auto tw-h-[16px] tw-w-[16px] tw-rounded-full before:tw-rounded-full;
}
.ui-screenplay-block-connects .ui-checkbox:hover .ui-checkbox-checkmark {
	@apply tw-border-secondary;
}
.ui-screenplay-block-connects .ui-checkbox .ui-checkbox-label {
	@apply tw-pl-[5px] tw-text-[14px] tw-text-placeholder;
}
.ui-screenplay .ui-screenplay-block-status {
}
.ui-screenplay .ui-screenplay-block-buttons {
	@apply tw-absolute tw-left-[50%] tw-top-full tw-flex tw-min-w-full tw-translate-x-[-50%] tw-items-center tw-justify-center tw-gap-x-[4px] tw-pt-[8px] tw-transition-opacity tw-duration-200 xl:tw-pointer-events-none xl:tw-opacity-0;
}
.ui-screenplay .ui-screenplay-block:hover .ui-screenplay-block-buttons {
	@apply tw-pointer-events-auto tw-opacity-100;
}
.ui-screenplay .ui-screenplay-block-buttons button {
	@extend .ui-button-tertiary;
}
.ui-screenplay .ui-screenplay-block-buttons svg {
	@apply tw-h-[20px] tw-w-[20px];
}
.ui-screenplay .ui-screenplay-body-connects {
	@apply tw-pointer-events-none tw-absolute tw-inset-0 tw-z-[20]  tw-h-[100%] tw-w-[100%] tw-text-placeholder;
}

.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='text'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='text'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='text'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#ffae34];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='video'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='video'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='video'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#79ff63];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='audio'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='audio'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='audio'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#78a6ff];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='image'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='image'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='image'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#9b8bff];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='test'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='test'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='test'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#5bceff];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='glossary'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='glossary'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='glossary'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#ff5bbe];
}
.ui-screenplay .ui-screenplay-buttons .ui-button[data-block-type='longread'] svg,
.ui-screenplay .ui-screenplay-buttons .ui-menu-item[data-block-type='longread'] svg,
.ui-screenplay .ui-screenplay-block[data-block-type='longread'] .ui-screenplay-block-body-title svg {
	@apply tw-text-[#1bf8d0];
}
.ui-screenplay .ui-screenplay-block[data-block-type='text'] {
	@apply hover:tw-border-[#ffae34];
}
.ui-screenplay .ui-screenplay-block[data-block-type='video'] {
	@apply hover:tw-border-[#79ff63];
}
.ui-screenplay .ui-screenplay-block[data-block-type='audio'] {
	@apply hover:tw-border-[#78a6ff];
}
.ui-screenplay .ui-screenplay-block[data-block-type='image'] {
	@apply hover:tw-border-[#9b8bff];
}
.ui-screenplay .ui-screenplay-block[data-block-type='test'] {
	@apply hover:tw-border-[#5bceff];
}
.ui-screenplay .ui-screenplay-block[data-block-type='glossary'] {
	@apply hover:tw-border-[#ff5bbe];
}
.ui-screenplay .ui-screenplay-block[data-block-type='longread'] {
	@apply hover:tw-border-[#1bf8d0];
}

/*TODO: rewrite to tailwindcss*/

/*TODO: change z-index scheme for popped elements*/

.ui-editor {
	@apply tw-relative tw-w-full;
}
/*временный костыль, пока редактор ломается при клике до инициализации (даже по лаудеру)*/
.ui-editor.ui-editor-loading {
	@apply tw-pointer-events-none
}
.ui-editor .codex-editor {
	@apply tw-flex tw-flex-col;
}
.ui-editor .codex-editor-overlay__rectangle {
	@apply !tw-border-none !tw-bg-transparent;
}
/*
.ui-editor-primary .ce-block--selected .ce-block__content {
	@apply tw-bg-secondary tw-text-white
}
*/
.ui-editor .codex-editor__redactor {
	@apply tw-min-h-full !tw-pb-0 tw-flex-grow tw-flex-shrink;
}
/*TODO: rework for our spinner*/
.ui-editor .codex-editor__loader {
	@apply tw-absolute tw-inset-0 !tw-h-auto !tw-min-h-full tw-cursor-wait tw-z-20;
}
.ui-editor .ce-block__content,
.ui-editor .ce-toolbar__content {
	@apply !tw-max-w-full;
}
.ui-editor .ce-inline-toolbar__toggler-and-button-wrapper {
	@apply !tw-p-0;
}
.ui-editor .ce-inline-toolbar__dropdown {
	@apply !tw-m-0;
}
.ui-editor .ce-toolbar {
	@apply tw-pointer-events-none tw-z-[10];
}
.ui-editor .ce-toolbar__actions {
	@apply !tw-absolute !tw-inset-x-0;
}
.ui-editor .ce-toolbar__actions > * {
	@apply tw-pointer-events-auto;
}
.ui-editor .ce-toolbar__plus {
	@apply !tw-absolute !tw-left-0 tw-top-[50%] tw-translate-y-[-50%];
}
.ui-editor .ce-toolbar__settings-btn {
	@apply !tw-absolute !tw-right-0 tw-top-[50%] tw-translate-y-[-50%];
}
.ui-editor .ce-toolbox {
	@apply tw-absolute tw-left-0;
}
.ui-editor .ce-settings {
	@apply tw-absolute tw-right-0;
}
.ui-editor .ce-toolbox .ce-popover,
.ui-editor .ce-settings .ce-popover {
	@apply !tw-relative;
}

/* Delimeter */

.ui-editor .ce-delimiter {
	@apply tw-w-full tw-text-center
}

/* Embed */

.embed-tool--loading .embed-tool__content,
.embed-tool--loading .embed-tool__caption {
	@apply tw-hidden
}
.embed-tool--loading .embed-tool__preloader {
	@apply tw-block
}
.embed-tool__preloader {
	@apply tw-hidden tw-relative before:tw-absolute before:tw-z-10 before:tw-left-[50%] before:tw-top-[50%] before:tw-translate-x-[-50%] before:tw-translate-y-[-50%]
}
.embed-tool__preloader {
    height: 200px;
    border-radius: 5px;
    border: 1px solid #e6e9eb;
}
.embed-tool__preloader::before {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid #cdd1e0;
	border-top-color: #388ae5;
	animation: embed-preloader-spin 2s infinite linear;
}
.embed-tool__url {
	position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 250px;
    color: #7b7e89;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.embed-tool__content {
	@apply tw-w-full
}
.embed-tool__caption {
	margin-top: 7px;
}
.embed-tool__caption[contentEditable=true][data-placeholder]::before{
	position: absolute;
	content: attr(data-placeholder);
	color: #707684;
	font-weight: normal;
	opacity: 0;
}
.embed-tool__caption[contentEditable=true][data-placeholder]:empty::before {
	opacity: 1;
}
.embed-tool__caption[contentEditable=true][data-placeholder]:empty:focus::before {
	opacity: 0;
}

@keyframes embed-preloader-spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}

/* Image */

.image-tool {
	--bg-color: #cdd1e0;
	--front-color: #388ae5;
	--border-color: #e8e8eb;
}
.image-tool__image {
	border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;
}
.image-tool__image-picture {
	max-width: 100%;
    vertical-align: bottom;
    display: block;
}
.image-tool__image-preloader {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-size: cover;
	margin: auto;
	position: relative;
	background-color: var(--bg-color);
	background-position: center center;
}
.image-tool__image-preloader:after {
	content: "";
	position: absolute;
	z-index: 3;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 2px solid var(--bg-color);
	border-top-color: var(--front-color);
	left: 50%;
	top: 50%;
	margin-top: -30px;
	margin-left: -30px;
	animation: image-preloader-spin 2s infinite linear;
	box-sizing: border-box;
}
.image-tool__caption[contentEditable="true"][data-placeholder]::before {
	position: absolute !important;
	content: attr(data-placeholder);
	color: #707684;
	font-weight: normal;
	display: none;
}
.image-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
	@apply tw-block
}
.image-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
	@apply tw-hidden
}

.image-tool--empty .image-tool__image,
.image-tool--empty .image-tool__caption,
.image-tool--loading .image-tool__caption {
	@apply tw-hidden
}
.image-tool .cdx-button {
	@apply tw-flex tw-items-center tw-justify-center
}
.image-tool .cdx-button svg {
	height: auto;
    margin: 0 6px 0 0;
}
.image-tool--filled .cdx-button,
.image-tool--filled .image-tool__image-preloader {
	@apply tw-hidden
}

.image-tool--loading .image-tool__image {
	min-height: 200px;
	display: flex;
	border: 1px solid var(--border-color);
	background-color: #fff;
}
.image-tool--loading .cdx-button,
.image-tool--loading .image-tool__image-picture {
	@apply tw-hidden
}
.image-tool--stretched .image-tool__image-picture {
	@apply tw-w-full
}

@keyframes image-preloader-spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}

/* Attachment */

.cdx-attachment {
	--color-line: #EFF0F1;
	--color-bg: #fff;
	--color-bg-secondary: #F8F8F8;
	--color-bg-secondary--hover: #f2f2f2;
	--color-text-secondary: #707684;
}
.cdx-attachment--with-file {
	@apply tw-flex tw-flex-col tw-gap-y-[10px]
}
.cdx-attachment__file-info {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border: 1px solid var(--color-line);
    border-radius: 7px;
    background: var(--color-bg);
}
.cdx-attachment__file-info .cdx-attachment__file-details {
	display: grid;
	grid-gap: 4px;
	max-width: calc(100% - 80px);
	margin: auto 0;
	flex-grow: 2;
}
.cdx-attachment__file-info .cdx-attachment__download-button {
	display: flex;
	align-items: center;
	background: var(--color-bg-secondary);
	padding: 6px;
	border-radius: 6px;
	margin: auto 0 auto auto;
}
.cdx-attachment__file-info .cdx-attachment__download-button:hover {
	background: var(--color-bg-secondary--hover);
}
.cdx-attachment__file-info .cdx-attachment__download-button svg {
	width: 20px;
	height: 20px;
	fill: none;
}
.cdx-attachment__file-info .cdx-attachment__file-icon {
	@apply tw-relative
}
.cdx-attachment__file-info .cdx-attachment__file-icon-background {
	background-color: #333;
	color: white;

	width: 32px;
	height: 32px;
	margin-right: 12px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cdx-attachment__file-info .cdx-attachment__file-icon-label {
	position: absolute;
	left: 3px;
	top: 11px;
	background: inherit;
	text-transform: uppercase;
	line-height: 1em;
	color: #fff;
	padding: 1px 2px;
	border-radius: 3px;
	font-size: 10px;
	font-weight: bold;
	font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
	letter-spacing: 0.02em;
}
.cdx-attachment__file-info .cdx-attachment__file-icon svg {
	width: 20px;
    height: 20px;
}
.cdx-attachment__file-info .cdx-attachment__file-icon path {
	stroke: #fff;
}
.cdx-attachment__file-info .cdx-attachment__size {
	color: var(--color-text-secondary);
	font-size: 12px;
	line-height: 1em;
}
.cdx-attachment__file-info .cdx-attachment__size::after {
	content: attr(data-size);
    margin-left: 0.2em;
}
.cdx-attachment__file-info .cdx-attachment__title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	outline: none;
	max-width: 90%;
	font-size: 14px;
	font-weight: 500;
	line-height: 1em;
	@apply tw-cursor-text;
}
.cdx-attachment__file-info .cdx-attachment__title:empty::before {
	content: attr(data-placeholder);
	color: #7b7e89;
}
.cdx-attachment--loading .cdx-attachment__title,
.cdx-attachment--loading .cdx-attachment__file-icon,
.cdx-attachment--loading .cdx-attachment__size,
.cdx-attachment--loading .cdx-attachment__download-button,
.cdx-attachment--loading .cdx-attachment__button {
	@apply tw-opacity-0
}
.cdx-attachment--loading .cdx-attachment__button {
	display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-radius: 7px;
    font-weight: 500;
}
.cdx-attachment--loading .cdx-attachment__button svg {
	@apply tw-mt-0
}

.cdx-attachment__file-view video {
	@apply tw-w-full tw-h-auto tw-max-h-[600px]
}
.cdx-attachment__file-view audio {
	@apply tw-w-full
}

/* Header */

.ce-header {
	padding-bottom: 3px;
	margin: 0;
	line-height: 1.25em;
	outline: none;
}
.ce-header p,
.ce-header div {
	@apply !tw-p-0 !tw-m-0
}
.ce-header__icon {
}
.ce-header[contentEditable='true'][data-placeholder]::before {
	position: absolute;
	content: attr(data-placeholder);
	color: #707684;
	font-weight: normal;
	display: none;
	cursor: text;
}
.ce-header[contentEditable='true'][data-placeholder]:empty::before {
	@apply tw-block
}
.ce-header[contentEditable='true'][data-placeholder]:empty:focus::before {
	@apply tw-hidden
}
.ce-header--right {
	@apply tw-text-right
}
.ce-header--center {
	@apply tw-text-center
}
.ce-header--left {
	@apply tw-text-left
}
.ce-header--justify {
	@apply tw-text-justify
}

/* List */

.cdx-list {
	margin: 0;
	padding: 0;
	outline: none;
	counter-reset: item;
	list-style: none;
}
.cdx-list__item {
	line-height: 1.6em;
	display: flex;
	margin: 2px 0;
}
.cdx-list__item:before {
	counter-increment: item;
	margin-right: 5px;
	white-space: nowrap;
}
.cdx-list__item[contenteditable] {
	outline: none;
}
.cdx-list__item-body {
	flex-grow: 2;
}
.cdx-list__item-content {
	flex-basis: 100%;
	word-break: break-word;
	white-space: pre-wrap;
}
.cdx-list__item-children {
	flex-basis: 100%;
}
.cdx-list__settings {
	@apply tw-flex
}
.cdx-list__settings .cdx-settings-button {
	@apply tw-w-[50%]
}
.cdx-list--ordered .cdx-list__item:before {
	content: counters(item, '.') '. ';
}
.cdx-list--unordered .cdx-list__item:before {
	content: '•';
}

/* Paragraph */

.ui-editor-blocks-paragraph {
	line-height: 1.6em;
	outline: none;
}

.ui-editor-blocks-paragraph[data-placeholder]:empty::before {
	content: attr(data-placeholder);
	color: #707684;
	font-weight: normal;
	opacity: 0;
}

.codex-editor--empty .ce-block:first-child .ui-editor-blocks-paragraph[data-placeholder]:empty::before {
	@apply tw-opacity-100
}

.codex-editor--toolbox-opened .ce-block:first-child .ui-editor-blocks-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ui-editor-blocks-paragraph[data-placeholder]:empty:focus::before {
	@apply tw-opacity-0
}

.ui-editor-blocks-paragraph p:first-of-type {
	@apply tw-mt-0
}

.ui-editor-blocks-paragraph p:last-of-type {
	@apply tw-mb-0
}

/* Table */

/* tc- project's prefix*/
.tc-wrap {
	--color-background: #f9f9fb;
	--color-text-secondary: #7b7e89;
	--color-border: #e8e8eb;
	--cell-size: 34px;
	--toolbox-icon-size: 18px;
	--toolbox-padding: 6px;
	--toolbox-aiming-field-size: calc(var(--toolbox-icon-size) + 2 * var(--toolbox-padding));

	border-left: 0px;
	position: relative;
	height: 100%;
	width: 100%;
	margin-top: var(--toolbox-icon-size);
	box-sizing: border-box;
	display: grid;
	grid-template-columns: calc(100% - var(--cell-size)) var(--cell-size);
}

.tc-wrap--readonly {
	grid-template-columns: 100% var(--cell-size);
}

.tc-wrap svg {
	vertical-align: top;
}

@media print {
	.tc-wrap {
		border-left: 1px solid var(--color-border);
		grid-template-columns: 100% var(--cell-size);
	}

	.tc-wrap .tc-row::after {
		display: none;
	}
}

.tc-table {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	font-size: 14px;
	border-top: 1px solid var(--color-border);
	line-height: 1.4;
}
.tc-table::after {
	position: absolute;
	content: '';
	width: calc(var(--cell-size));
	height: 100%;
	left: calc(-1 * var(--cell-size));
	top: 0;
}
.tc-table::before {
	position: absolute;
	content: '';
	width: 100%;
	height: var(--toolbox-aiming-field-size);
	top: calc(-1 * var(--toolbox-aiming-field-size));
	left: 0;
}
.tc-table--heading .tc-row:first-child {
	font-weight: 600;
	border-bottom: 2px solid var(--color-border);
}
.tc-table--heading .tc-row:first-child [contenteditable]:empty::before {
	content: attr(heading);
	color: var(--color-text-secondary);
}
.tc-table--heading .tc-row:first-child::after {
	bottom: -2px;
	border-bottom: 2px solid var(--color-border);
}

.tc-add-column,
.tc-add-row {
	display: flex;
	color: var(--color-text-secondary);
}

@media print {
	.tc-add {
		display: none;
	}
}

.tc-add-column {
	padding: 4px 0;
	justify-content: center;
	border-top: 1px solid var(--color-border);
}

@media print {
	.tc-add-column {
		display: none;
	}
}

.tc-add-row {
	height: var(--cell-size);
	align-items: center;
	padding-left: 4px;
	position: relative;
}
.tc-add-row::before {
	content: '';
	position: absolute;
	right: calc(-1 * var(--cell-size));
	width: var(--cell-size);
	height: 100%;
}
@media print {
	.tc-add-row {
		display: none;
	}
}

.tc-add-column,
.tc-add-row {
	transition: 0s;
	cursor: pointer;
	will-change: background-color;
}
.tc-add-column:hover,
.tc-add-row:hover {
	transition: background-color 0.1s ease;
	background-color: var(--color-background);
}
.tc-add-row {
	margin-top: 1px;
}
.tc-add-row:hover::before {
	transition: 0.1s;
	background-color: var(--color-background);
}

.tc-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
	position: relative;
	border-bottom: 1px solid var(--color-border);
}
.tc-row::after {
	content: '';
	pointer-events: none;
	position: absolute;
	width: var(--cell-size);
	height: 100%;
	bottom: -1px;
	right: calc(-1 * var(--cell-size));
	border-bottom: 1px solid var(--color-border);
}
.tc-row--selected {
	background: var(--color-background);
}

.tc-row--selected::after {
	background: var(--color-background);
}

.tc-cell {
	border-right: 1px solid var(--color-border);
	padding: 6px 12px 6px 12px;
	overflow: hidden;
	outline: none;
	line-break: normal;
}
.tc-cell--selected {
	background: var(--color-background);
}

.tc-wrap--readonly .tc-row::after {
	@apply tw-hidden
}

.tc-toolbox {
	--toolbox-padding: 6px;
	--popover-margin: 30px;
	--toggler-click-zone-size: 30px;
	--toggler-dots-color: #7b7e89;
	--toggler-dots-color-hovered: #1d202b;

	position: absolute;
	cursor: pointer;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.1s;

	will-change: left, opacity;
}
.tc-toolbox--column {
	top: calc(-1 * (var(--toggler-click-zone-size)));
	transform: translateX(calc(-1 * var(--toggler-click-zone-size) / 2));
	will-change: left, opacity;
}
.tc-toolbox--row {
	left: calc(-1 * var(--popover-margin));
	transform: translateY(calc(-1 * var(--toggler-click-zone-size) / 2));
	margin-top: -1px; /* because of top border */
	will-change: top, opacity;
}

.tc-toolbox--showed {
	@apply tw-opacity-100
}
.tc-toolbox .tc-popover {
	position: absolute;
	top: 0;
	left: var(--popover-margin);
}

.tc-toolbox__toggler {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--toggler-click-zone-size);
	height: var(--toggler-click-zone-size);
	color: var(--toggler-dots-color);
	opacity: 0;
	transition: opacity 150ms ease;
	will-change: opacity;
}
.tc-toolbox__toggler:hover {
	color: var(--toggler-dots-color-hovered);
}
.tc-toolbox__toggler svg {
	fill: currentColor;
}

.tc-wrap:hover .tc-toolbox__toggler {
	@apply tw-opacity-100
}

.tc-settings .cdx-settings-button {
	@apply tw-w-[50%] tw-m-0
}

.tc-popover {
	--color-border: #eaeaea;
	--color-background: #fff;
	--color-background-hover: rgba(232, 232, 235, 0.49);
	--color-background-confirm: #e24a4a;
	--color-background-confirm-hover: #d54040;
	--color-text-confirm: #fff;

	background: var(--color-background);
	border: 1px solid var(--color-border);
	box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
	border-radius: 6px;
	padding: 6px;
	display: none;
	will-change: opacity, transform;
}

.tc-popover--opened {
	display: block;
	animation: menuShowing 100ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.tc-popover__item {
	display: flex;
	align-items: center;
	padding: 2px 14px 2px 2px;
	border-radius: 5px;
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
}

.tc-popover__item:hover {
	background: var(--color-background-hover);
}

.tc-popover__item:not(:last-of-type) {
	margin-bottom: 2px;
}

.tc-popover__item-icon {
	display: inline-flex;
	width: 26px;
	height: 26px;
	align-items: center;
	justify-content: center;
	background: var(--color-background);
	border-radius: 5px;
	border: 1px solid var(--color-border);
	margin-right: 8px;
}

.tc-popover__item-label {
	line-height: 22px;
	font-size: 14px;
	font-weight: 500;
}

.tc-popover__item--confirm {
	background: var(--color-background-confirm);
	color: var(--color-text-confirm);
}
.tc-popover__item--confirm:hover {
	background-color: var(--color-background-confirm-hover);
}
.tc-popover__item--confirm-icon {
	background: var(--color-background-confirm);
	border-color: rgba(0, 0, 0, 0.1);
}
.tc-popover__item--confirm-icon svg {
	transition: transform 200ms ease-in;
	transform: rotate(90deg) scale(1.2);
}
.tc-popover__item--hidden {
	@apply tw-hidden
}

@keyframes menuShowing {
	0% {
		opacity: 0;
		transform: translateY(-8px) scale(0.9);
	}
	70% {
		opacity: 1;
		transform: translateY(2px);
	}
	to {
		transform: translateY(0);
	}
}

/* Underline */

.cdx-underline {
	@apply tw-underline
}
